@tailwind base;
@tailwind components;
@tailwind utilities;

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  display: none;
}

*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.125);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.453);
  border-radius: 5px;
  border: rgb(0, 0, 0);
}


body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
